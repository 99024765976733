<template>
  <div
    id="app"
  >
    <router-view />
  </div>
</template>
<script>
import { Vue, Component } from 'vue-property-decorator'

export default @Component({})
class App extends Vue {

}
</script>
<style lang="scss" scoped>

</style>
