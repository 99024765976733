import Vue from 'vue'
import TextHighlight from 'vue-text-highlight'
import StickyTable from 'element-ui-sticky-table'
import VueMoment from 'vue-moment'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import ElementUI from 'element-ui'
import 'bootstrap-4-grid/css/grid.css'
import '@/assets/theme/index.css'
import '@/assets/styles/app.scss'
import * as Sentry from '@sentry/vue'

Vue.component('el-table', StickyTable)
Vue.component('text-highlight', TextHighlight)
Vue.use(VueMoment)
Vue.use(ElementUI, { size: 'small', i18n: (key, value) => i18n.t(key, value) })

Vue.config.productionTip = false

Sentry.init({
  Vue,
  dsn: 'https://3f107484a9c022b31ea647c31e3d1f86@o338670.ingest.sentry.io/4505939046825984',
  integrations: [new Sentry.BrowserTracing({
    // traceFetch: false,
    // // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/assets-cms\.yafocapital\.com/, /^https:\/\/assets\.yafocapital\.com/],
    routingInstrumentation: Sentry.vueRouterInstrumentation(router)
  }), new Sentry.Replay()], // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

new Vue({
  router, store, i18n, render: h => h(App)
}).$mount('#app')
